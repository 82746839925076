@media print {
    .PdFviewer_div {
        display: none !important;
    }
}

@-moz-document url-prefix() {
    .header {
        width: 100% !important;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: flex-end !important;
    }
    .PdFviewer_div {
        margin-top: -64px !important;
        width: 100% !important;
        border-radius: 16px !important;
    }
    .cross_Btn {
        position: absolute !important;
        margin-bottom: -0px !important;
        margin-right: -25px !important;
        z-index: 1 !important;
    }
}


.crossIcon {
    width: 25px !important;
    height: 24px !important;
}

.header {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}