@import url('../colors/colors.css');

.Scheduled {
    background: var(--ScheduledBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.Processing {
    background: var(--ScheduledBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.Received {
    /* UI Properties */
    background: var(--ReceivedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.UnableToProcess {
    background: var(--UnableToProcessBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.ReadyForAudit {
    background: var(--ReadyForAuditBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AuditInProgress {
    background: var(--AuditInProgressBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.Completed {
    background: var(--CompletedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AddendumReceived {
    background: var(--ReceivedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AddendumReadyForAudit {
    background: var(--AddendumReadyBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: 150px;
}
.AddendumInProgress {
    background: var(--AddendumInProgressBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AddendumMerged {
    background: var(--AddendumMergedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AddendumCompleted {
    background: var(--CompletedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.ValidatedRecord {
    background: var(--ValidateBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.ValidatedCoding {
    background: var(--ValidateBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.ValidatedRecordCoding {
    background: var(--ValidateBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AuditSuspended {
    background: var(--AuditSuspendedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AuditOverdue {
    background: var(--AuditOverdueBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.AuditProcessingEscalated {
    background: var(--AuditEscalatedBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.SentToInterface {
    background: var(--SentToInterfaceBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
.NoProcessRequired {
    background: var(--NoProcessBG) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    margin-left: 8px;
    width: fit-content;
}
