.CustomFilter {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
}

.nameDiv {
    display: flex !important;
    align-items: center !important;
    margin-left: 20px
}

.btn {
    min-Width: 127px !important;
    height: 34px !important;
    padding: 5px 15px 5px 15px !important;
    /* UI Properties */
    background: #F7F8F9 !important;
    border: 1px solid #D1D1D1 !important;
    text-Transform: initial !important;
    text-align: start !important;
    justify-content: space-between
}
.iconbuttondiv{
    position: absolute !important;
    top: -7px !important;
    left: -7px !important;
    display: flex !important;
}
.iconbtn{
    background: #F7F8F9 !important;
    border: 1px solid #D1D1D1 !important;
    width: 16px !important;
    height: 16px !important;
}


.active {
    background-color: green;
}
.unactive {
    background-color: white;
}
.arrowpopper:after {
    border-top: 10px solid transparent !important;
    border-left: 15px solid #ffffff !important;
    border-bottom: 10px solid transparent !important;
    content: '' !important;
    width: 0;
    height: 0;
    position: absolute !important;
    top: -10px !important;
    left: -15px !important;
}
.mainFilter{
    display: flex ;
    flex-direction: row ;
    gap: 20px !important;
 }
 .Highlightspan{
    margin-top:10px;
    position:relative;
 }
