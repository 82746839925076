.attachment{
    display: flex;
    flex-direction: column;
  }
  .attachment.start{
    justify-content: flex-start;
  }
  .attachment.end{
    justify-content: flex-end;
  }
  .sendertimer{
    align-self: flex-end !important;
    margin-right: 80px !important;
    margin-top: 5px !important;
  }
  .receivertimer{
    align-self: flex-start !important;
    margin-left: 80px !important;
    margin-top: 5px !important;
  }
.attachmentContainter{
    display: flex;
    align-items: center;
    margin-left: 18px;
    margin-right: 18px !important;
  }
  .attachment.mine .attachmentContainter{
    justify-content: flex-end;
    max-width: none;
  }