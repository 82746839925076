.docxContainer{
    background: white !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    padding-top: 2% !important;
    padding-bottom: 5% !important;
    overflow: auto !important;
}
.docxContainer table {
    border-collapse: collapse !important;
    width: 100% !important;
    height: 100% !important;
}
.docxContainer th,
.docxContainer td {
    border: 1px solid black !important;
    padding: 8px !important;
}
.docxContainer img{
width: -webkit-fill-available !important;
}

.crossIcon{
    width: 25px !important;
    height: 24px !important;
}
.header{
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}
.brokenPlaceholder{
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}