.comment {
    /* width: 402px !important; */
    width: 100% !important;
    /* min-height: 176px; */
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 4px #00000014 !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 8px !important;
    opacity: 1;
    margin-bottom: 15px !important;
}
.notify {
    width: 402px;
    min-height: 126px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 4px #00000014 !important;
    border: 1px solid #AFD8F3 !important;
    border-radius: 8px !important;
    opacity: 1;
}
.expandcard {
    /* width: 354px !important; */
    height: 83px !important;
    /* UI Properties */
    background: #F7F8F9 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important; 
    opacity: 1 !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
}

.textfd {
    /* width: 319px; */
    margin: 17px 22px 18px 13px !important;
    height: 48px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
}

.btndiv {
    position: absolute !important;
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important; 
    /* align-items: end; */
    top: -15px !important;
    left: 15px !important;
    /* margin: -15px 0px 0px -73px; */
}

.cmtSendDiv {
    height: 71px !important;
    /* UI Properties */
    background: #F7F8F9 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000F !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    padding: 8px !important;
    bottom: 0 !important;
    position: absolute !important;
}

.cmtSendtextfd {
    height: 55px !important;
    width: 100% !important;
    margin: 8px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 28px !important;
    opacity: 1 !important;
}