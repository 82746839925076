.row{ 
    margin: 5px !important;
    text-align: center !important;
    padding: 15px !important;
    gap: 10px !important;
    display: flex !important;
    justify-content: center !important;
}
.gridRoot{ 
    padding: 15px 20px !important;
}