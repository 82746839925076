.header {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    position: relative !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    /* box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #e6e6e6 !important; */
    border-radius: 5px !important;
    opacity: 1 !important;
    width: 408px !important;
    height: 360px !important;
    margin: 16px;
}
.countsContainer {
    position: absolute !important;
    top: 27px !important;
    right: 27px !important;
}
.textcontainer {
    margin-top: 50px !important;
}
.footer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 30px !important;
}
.buttonstyles {
    width: 134px !important;
    height: 31px !important;
    background: #427be0 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: none !important;
    cursor: pointer !important;
}
.buttonstylesdisabled {
    width: 134px !important;
    height: 31px !important;
    background: #959daa 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    text-transform: none !important;
    cursor: auto !important;
}
.continuestyle {
    cursor: pointer !important;
    margin-top: 10px !important;
}
.continuestyledisabled{
    cursor: auto !important;
    margin-top: 10px !important;
}