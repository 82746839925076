.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubblecontainer {
  font-size: 14px;
  display: flex;
  margin-left: 18px;
  margin-right: 18px !important;
}

.message.mine .bubblecontainer {
  justify-content: flex-end;
}

.message.start .bubblecontainer .bubble {
  margin-top: 16px;
  border-top-left-radius: 8px;
}
/* .bubbleremove {
  width: 100px !important;
  height: 34px !important;
  background: #f0f0f0 0% 0% no-repeat padding-box !important;
  border-radius: 18px !important;
  opacity: 1 !important;
  position: absolute !important;
  right: 0 !important;
  margin-right: 48px !important;
  display: grid !important;
  align-items: center !important;
 
} */
.textcolour {
  color: #868686 !important;
  text-align: center !important;
}
.message.end .bubblecontainer .bubble {
  border-bottom-left-radius: 8px;
  margin-right: 25px;
  margin-left: 25px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubblecontainer .bubble {
  margin-top: 16px;
  border-top-right-radius: 8px;
}

.message.mine.end .bubblecontainer .bubble {
  border-bottom-right-radius: 8px;

  /* margin-bottom: 10px; */
}

.message .bubblecontainer .bubble {
  margin: 1px 0px 0px 25px !important;
  background: #58b597;
  word-break: break-all;
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  max-width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 10%;
  min-height: 37px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.message.mine.start .bubblecontainer .bubbleremove {
  margin-top: 16px;

  border-top-right-radius: 18px;
}

.message.mine.end .bubblecontainer .bubbleremove {
  border-bottom-right-radius: 18px;
  /* margin-bottom: 10px; */
}
.message .bubblecontainer .bubbleremove {
  background: #f0f0f0 0% 0% no-repeat padding-box !important;
  word-break: break-all;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 34px !important;
  border-radius: 18px !important;
  opacity: 1 !important;
  padding: 10px 15px !important;
  margin: 1px 25px 0px 25px !important;
}
.message.mine .bubblecontainer .bubble {
  background: #45a4ea;
  color: white;
  word-break: break-all;
  margin-right: 25px !important;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  min-height: 37px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.endTimer {
  display: flex;
}
.sendertimer {
  align-self: flex-end !important;
  margin-right: 80px !important;
  margin-top: 5px !important;
  /* margin-top: 10px !important; */
}
.receivertimer {
  align-self: flex-start !important;
  margin-left: 80px !important;
  margin-top: 5px !important;
}
.shownSpan {
  display: none !important;
}
.senderWhiteDiv {
  width: 25px !important;
  height: 20px !important;
  background: white !important;
  position: absolute !important;
  right: 0 !important;
  margin-right: 46px !important;
  border-bottom-left-radius: 20px !important;
  display: flex !important;
  align-self: flex-end !important;
  margin-bottom: 6px !important;
}
.senderColorDiv {
  width: 34px !important;
  height: 20px !important;
  background: #45a4ea !important;
  position: absolute !important;
  right: 0 !important;
  margin-right: 48px !important;
  border-bottom-right-radius: 20px !important;
  display: flex !important;
  align-self: flex-end !important;
  margin-bottom: 3px !important;
}
.receiverWhiteDiv {
  width: 23px !important;
  height: 20px !important;
  background: white !important;
  position: absolute !important;
  left: 0 !important;
  margin-left: 48px !important;
  border-bottom-right-radius: 20px !important;
  display: flex !important;
  align-self: flex-end !important;
  margin-bottom: 6px !important;
}
.receiverColorDiv {
  width: 35px !important;
  height: 20px !important;
  background: #58b597 !important;
  position: absolute !important;
  left: 0 !important;
  margin-left: 48px !important;
  border-bottom-left-radius: 20px !important;
  display: flex !important;
  align-self: flex-end !important;
  margin-bottom: 4px !important;
}
.timerDiv {
  text-align: center;
  position: relative;
}
/* .timerDiv::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #ECEFF1 !important;
  background: #ECEFF1 !important;
  width: 100%;
  transform: translateY(-50%);
} */
.inDiv {
  display: flex;
  justify-content: center;
  position: relative;
}
.dot {
  background: white;
  padding-left: 5px;
  padding-right: 5px;
}
.leftAlignment {
  margin-left: 25px !important;
}
.rightAlignment {
  margin-right: 25px !important;
}

.tickstyles {
  width: 13px !important;
  height: 8px !important;
  margin-left: 5px !important;
}
