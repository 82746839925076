.ViewAudios {
    margin-top: 5px !important;
    overflow-y: auto !important; 
    height: calc(100vh - 201px) !important; 
    position:relative !important;
}
.loder_rootDiv{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
}
.cross_Btn{
    position: absolute !important;
    margin-top: -27px !important;
    right: 5px !important;
}
.crossIcon{
    width: 30px !important;
    height: 32px !important;
}
.AudioPlayer {
    width: 413px !important;
    height: 180px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 12px !important;
    opacity: 1 !important;
}

.container{
    height: 20px !important;
    background-color: #ccc !important;
    position: relative !important;
}
.container .progressbar{
    position: absolute !important;
    height: 100% !important;
    background-color: rgb(125, 44, 255) !important;
    animation: progress-animation 5s forwards !important;
}

@keyframes progress-animation {
    0% { width: 0%;}
    100% { width: 100%;}
}
.audioCard{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000014 !important;
    border-radius: 7px !important;
    opacity: 1 !important;
    margin: 20px !important ;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding-right: 14px !important;
}