.chatHeader{
    height: 45px !important;
    /* UI Properties */
    background: var(--unnamed-color-c4549a) 0% 0% no-repeat padding-box !important;
    background: #C4549A 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
}