.InvalidMessage {
    width: 358px !important;
    height: 196px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    display: flex !important;
    /* justify-content: center !important; */
    align-items: center !important;
    flex-direction: column !important;
    padding: 25px !important;
    text-align: center !important;
    gap: 10px !important;
}
.okbtn{
    height: 31px !important;
    /* UI Properties */
    background: #FF6361 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}
.errMessage{
    text-align: center !important;
    width: 60% !important;
}