:root {
    --primary1: #f7f7f7;
    --primary2: #ffffff;
    --primary3: #7c7c7c;
    --primary4: #c4549a;
    --primary5: #a0457f;
    --primary6: #f7f5f5;
    --primary7: #363636;
    --primary8: #4a6df4;
    --primary9: #e94362;
    --primary10: #427be0;
    --primary11: #00000014;
    --primary12: #000000;
    --primary13: #e6a438;
    --primary14: #3b074f;
    --primary15: #dc6943;
    --primary16: #4aa0d8;
    --primary17: #923693;
    --primary18: #fefdfd;
    --primary19: #eaeaea;
    --primary20: #edf0f2;
    --primary21: #f7f8f9;
    --primary22: #eceff1;
    --primary23: #00000029;
    --primary24: #dedede;
    --primary25: #939393;
    --primary26: #11213f;
    --primary27: #5f5f5f;
    --primary28: #d9baf4;
    --primary29: #661ca5;
    --primary30: #ffecee;
    --primary31: #bb2a33;
    --primary32: #e9f5ce;
    --primary33: #436f00;
    --primary34: #d9f5fd;
    --primary35: #0063b8;
    --primary36: #feefcb;
    --primary37: #925400;
    --primary38: #557ff6;
    --primary39: #26b4fe40;
    --primary40: #406ff5;
    --primary41: #f6a266;
    --primary42: #f4883b;
    --primary43: #65d195;
    --primary44: #39c477;
    --primary45: #ab7ad5;
    --primary46: #9355c9;
    --primary47: #aeaeae;
    --primary48: #e93323;
    --primary49: #313131;
    --primary50: #dddddd;
    --primary51: #d2d3d5ac;
    --primary52: #0000000f;
    --primary53: #d2d3d5;
    --primary54: #00000000;
    --primary55: #e2dfdf;
    --primary56: #6f6f6f;
    --primary57: #212427;
    --primary58: #ffd0d9;
    --primary59: #808991;
    --primary60: #303030;
    --primary61: #b5b5b5;
    --primary62: #0000001f;
    --primary63: #959daa;
    --primary64: #fcd6bb;
    --primary65: #b47800;
    --primary66: #707070;
    --primary67: #c0c2c3;
    --primary68: #7a808b;
    --primary69: #adadad;
    --primary70: #84b9fd;
    --primary71: #001e45;
    --primary72: #ff6361;
    --primary73: #848484;
    --primary74: #cccccc;
    --primary75: #afd8f3;
    --primary76: #1976d2;
    --primary77: #9d9d9d;
    --primary78: #868686;
    --primary79: #555353;
    --primary80: #727272;
    --primary81: #507ad9;
    --primary82: #747474;
    --primary83: #9c9ea6;
    --Received: #606060;
    --UnableToProcess: #e5adb6;
    --ReadyForAudit: #38407c;
    --AuditInProgress: #8b2ba8;
    --Completed: #436f00;
    --AddendumReady: #18354e;
    --AddendumInProgress: #000000;
    --AddendumMerged: #2a4502;
    --Validate: #925400;
    --AuditOverdue: #9b0000;
    --AuditSuspended: #9b0000;
    --AuditEscalated: #765f14;
    --SentToInterface: #436f00;
    --NoProcess: #a11c1c;
    --ScheduledBG: #d4d3d5;
    --ReceivedBG: #d4d3d5;
    --UnableToProcessBG: #ffecee;
    --ReadyForAuditBG: #bce7fd;
    --AuditInProgressBG: #e5cbff;
    --CompletedBG: #e9f5ce;
    --AddendumReadyBG: #9db4ff;
    --AddendumInProgressBG: #c183ff;
    --AddendumMergedBG: #c9e686;
    --ValidateBG: #feefcb;
    --AuditOverdueBG: #e5adb6;
    --AuditSuspendedBG: #ffb800;
    --AuditEscalatedBG: #fdf160;
    --SentToInterfaceBG: #e9f5ce;
    --NoProcessBG: #676767;
}
