.UserComponent{
    display:flex !important;
    flex-direction:row !important;
    align-items:center !important;
    gap:10px !important;
}
.textOverflow{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.specialities_div{
    display:flex !important;
    flex-direction:column !important;
}
.specialities_user_component{
    display:flex !important;
    flex-direction:row !important;
    gap:10px !important;
}