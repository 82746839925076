.rootDiv{
    margin: 33px 65px !important;
    display: flex !important;
    justify-content: space-evenly !important;
    flex-direction: column !important
}
.alertDiv{ 
    display: flex !important; 
    flex-direction: row !important; 
    gap: 28px !important 
}
.claimColumnDiv{
    gap: 25px !important;
    display: flex !important;
    flex-direction: column !important;
}
.claimColumnGap{
    gap: inherit !important;
    display: flex !important;
    flex-direction: column !important;
}
.claimAlignItem{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 20px !important
}