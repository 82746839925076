.valField {
    background: none !important;
    min-height: 20px !important;
    align-items: center !important;
    display: flex !important;
    padding-left: 20px !important;
}
.valField:hover {
    background: #f7f8f9 !important;
}
.labField {
    min-height: 20px !important;
    align-items: center !important;
    display: flex !important;
}
