.siteContentWraper {
    padding-top: 20px;
    opacity: 1;
    height: 98dvh;
    min-height: 100vh;
    overflow: hidden;
    background: #f7f7f7 0% 0% no-repeat padding-box;
}
@media screen and (min-width: 500px) {
    .siteContentWraper {
        width: calc(100% - 252px);
    }
}
.sidebarTransition {
    transition: all 1s ease-in !important;
}
