.TaskHeader{
    display: flex !important;
    margin-left: 20px !important;
    margin-right: 24px !important;
    height: 80px !important;
    min-height: 80px !important;
    justify-content: space-between !important;
    align-items:center !important;
}
.toolbarstyle{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
}
.badgecount{
    min-width: 53px !important;
    height: 34px !important;
    background: #ECF0FC 0% 0% no-repeat padding-box !important;
    border-radius: 16px !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.columnStyle{
    display: flex !important;
    flex-direction: column !important;
}