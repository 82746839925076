.chatContinueDiv{
    height: 64px !important;
    display: flex !important;
    width: 100% !important;
    text-transform: initial !important;
    border-radius: 0;
    align-items: center !important;
    justify-content: center !important;
    background: #427BE0 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000F !important;
    opacity: 1 !important;
}
.chatContinueDiv:disabled{
    background: #9F9F9F 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000F !important;
    opacity: 1 !important;
}
