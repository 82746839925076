.ReadOnlyField {
    height: 50px !important;
    min-height: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: center !important;
    text-align: center !important;
    margin-top: 15px !important;
}