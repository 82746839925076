.MainDiv {
    @media screen and (max-width: 2000px) {
        margin: 2px !important;
        transition: all 0.2s ease-in;
    }
    @media screen and (min-width: 2000px) {
        margin: 40px 20px 20px 20px !important;
        transition: all 0.2s ease-in;
    }
    /* margin: 40px 20px 20px 20px !important; */
}
.CustomCard {
    padding: 2px !important;
    /* margin-top: 30px !important; */
    /* height: 75vh !important; */
    box-shadow: 0px 1px 2px #00000014 !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 2px solid #eaeaea !important;
    border-radius: 4px !important;
}
.HeaderCell {
    color: rgb(33, 43, 54) !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
    background: #f7f8f9 0% 0% no-repeat padding-box !important;
}
.AddTaskRootDiv {
    margin: 10px 48px 50px 41px !important;
    height: 100% !important;
    min-height: 100% !important;
    display: grid !important;
}
.AddTaskFiledDiv {
    height: 50px !important;
    min-height: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: center !important;
    text-align: center !important;
    margin-top: 15px !important;
}
.HeaderDiv {
    height: 63px !important;
    min-height: 63px !important;
    width: 100% !important;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
    background: #f7f7f7 0% 0% no-repeat padding-box !important;
}
.FitDiv {
    width: 50% !important;
    text-align: initial !important;
}
.elispe {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap;
}
.ViewTaskFiledDiv {
    height: 50px !important;
    min-height: 40px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: center !important;
    text-align: center !important;
}
.avatarDiv {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
}
.footerDialogDiv {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 20px !important;
    margin: 50px !important;
}
.auditorMenuItemDiv {
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    align-items: center !important;
}
.textFiled {
    height: 40px !important;
    width: 195px !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    min-height: 40px !important;
    padding-left: 10px !important;
}
.textFiled::placeholder {
    font-size: 14px !important;
    font-style: italic !important;
}
.textFiledError {
    height: 40px !important;
    width: 195px !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    border-radius: 4px !important;
    min-height: 40px !important;
    padding-left: 10px !important;
}
.textFiledError::placeholder {
    font-size: 14px !important;
    font-style: italic !important;
}
.paginationDiv {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-top: 20px !important;
}
.searchfieldDiv {
    /* width: 100%; */
    /* display: flex !important; */
    flex-direction: row !important;
    justify-content: space-between !important;
}
.tasklabeldiv {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.searchtextfield {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
}
.audioLink {
    text-decoration: none !important;
    cursor: pointer !important;
    max-width: 150px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    display: block !important;
}
.dotbtn {
    width: 40px !important;
    height: 40px !important;
    color: black !important;
}
.icon3dot.path {
    fill: black !important;
    color: black !important;
}
.dotbtn:hover {
    background: rgb(216, 224, 255) !important;
}
.menuItem {
    border-bottom: 1px solid #f5f5f5 !important;
    width: 180px !important;
}
.filterBtn {
    min-width: 111px;
    height: 28px;
    padding: 0px 5px 0px 5px !important;
    /* UI Properties */
    background: #deeaff 0% 0% no-repeat padding-box;
    border: 1px solid #5a93fc;
    border-radius: 4px;
    opacity: 1;
    text-transform: initial;
    align-items: center !important;
    justify-content: space-evenly !important;
    display: flex !important;
}
.filterDiv {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
    margin-top: 10px !important;
}
.TaskHeader {
    display: flex !important;
    margin-left: 20px !important;
    margin-right: 24px !important;
    height: 80px !important;
    min-height: 80px !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.chatContinueDiv {
    height: 64px !important;
    display: flex !important;
    width: 100% !important;
    text-transform: initial !important;
    border-radius: 0;
    align-items: center !important;
    justify-content: center !important;
    background: #427be0 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000f !important;
    opacity: 1 !important;
}
.taskHeaderDiv {
    margin-top: 20px !important;
    height: 280px !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    display: flex !important;
}
.chartHeader {
    background-color: #ffffff !important;
    box-shadow: 0px 1px 2px #00000014 !important;
    border: 2px solid #eaeaea !important;
    border-radius: 4px !important;
    width: 45% !important;
    margin-right: 10px;
}
.chartHeaderDiv {
    display: flex !important;
    justify-content: space-between !important;
    padding: 13px 0px 2px 31px;
}
.barchartDiv {
    padding: 8px !important;
    height: 80% !important;
    display: flex !important;
    align-items: center;
}
.bubbleChartdiv {
    background-color: #ffffff !important;
    box-shadow: 0px 1px 2px #00000014 !important;
    border: 2px solid #eaeaea !important;
    border-radius: 4px !important;
    width: 65%;
}
.boxmode {
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    display: flex !important;
    width: 100% !important;
    height: 50vh;
}
.chartbarPadding {
    padding: 0px 40px 2px 31px;
}
.chartbubblePadding {
    padding: 13px 0px 2px 31px;
}
.valField {
    background: none !important;
    min-height: 45px !important;
    align-items: center !important;
    display: flex !important;
    padding-left: 20px !important;
}
.valField:hover {
    background: #f7f8f9 !important;
}
.labField {
    min-height: 45px !important;
    align-items: center !important;
    display: flex !important;
}
