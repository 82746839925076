.TopBar {
    display: flex;
    position: fixed;
    top: 0;
    padding-left: 50px;
    z-index: 10;
    height: 40px;
}
/* @media screen and (min-width: 2000px) {
    .TopBar {
        padding-left: 200px;
    }
} */
