.cardDiv {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e2dfdf !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 13px 25px 13px 25px !important;
  width: 100% !important;
}
.fileItem {
  margin-bottom: 10px !important;
}
.lowercase {
  text-transform: lowercase;
}
.noFileCenter{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  top: 45% !important;
  position: absolute;
  width: 100%;
 
}

.details {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 20px !important;
  text-align: start !important;
}

.svgStyles {
  width: 46px !important;
}

.rowDiv {
  display: flex !important;
  flex-direction: row !important;
}

.cardRoot {
 
  overflow-y: auto !important;
  /* overflow: hidden !important; */
  height: calc(100vh - 231px) !important;
}
.withPadding {
  padding: 28px 20px 28px 20px !important;
}

.noPadding {
  padding: 0 !important;
}


.bottomDiv {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.dot {
  width: 4px;
  height: 4px;
  background: #7a808b 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
}

.detailrow {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px !important;
  align-items: center !important;
}
.searchDiv{
  padding: 0px 0px 20px 0px !important;
}