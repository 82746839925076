.info {
    min-height: 45px !important ;
    width:100% !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000F !important;

    opacity: 1 !important ;
}
.infor1 {
    padding: 8px 16px 8px !important;
}
.patientInfo {
    display: flex !important;
    flex-direction: row !important;
}
.info2 {
    display: grid !important;
    grid-template-columns: 43.5% 56.5% !important;
}

.info3 {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
}

.nameInfo {
    display: flex !important;
    align-items: center !important;
}

.attachmentInfo {
    margin-left: 120px !important;
}

.arrowInfo {
    margin-left: auto !important;
    cursor: pointer !important;
}
.lastNameSpacing {
    margin-right: -88px !important;
}

.genderspace {
    margin-right: 127px !important;
}

