@import url('../colors/colors.css');

.typoHelveticalNormalprimary216234 {
    text-align: center;
    font-family: Helvetica, sans-serif !important;
    font-size: 16px !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
    opacity: 1 !important;
}
.typoRobotoNormalNormalprimary216233 {
    text-align: center;
    font-family: RobotoNormal, sans-serif !important;
    font-size: 16px !important;
    line-height: 2.065rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
    opacity: 1 !important;
}
.typoRobotoNormalNormalprimary7716233 {
    text-align: center;
    font-family: RobotoNormal, sans-serif !important;
    font-size: 16px !important;
    line-height: 2.065rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary77) !important;
    opacity: 1 !important;
}
.typoRobotoRegoularBoldprimary1216 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 16px !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    line-height: 1.3125rem !important;
}
.typoRobotoRegoularprimary121621 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary12) !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    font-size: 1rem !important;
}
.typoRobotoMediumprimary811621 {
    font-family: RobotoMedium, sans-serif !important ;
    color: var(--primary81) !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    font-size: 1rem !important;
}
.typoRobotoRegularprimary821621 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary82) !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    font-size: 1rem !important;
}
.typoRobotoRegularprimary831621 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary83) !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    font-size: 1rem !important;
}
.typoRobotoRegularprimary831318 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary83) !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    font-size: 13px !important;
}
.typoRobotoRegoularprimary781621 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary78) !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
}
.typoRobotoNormalprimary71419 {
    font-family: RobotoNormal, sans-serif !important ;
    color: var(--primary7) !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
}

.typoRobotoRegoular14 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary681621 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary68) !important;
}

.typoRobotoMediumprimary671833 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 2.0625rem !important;
    letter-spacing: -0.36px !important;
    color: var(--primary67) !important;
}
.typoRobotoMediumprimary742042 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.25rem !important;
    line-height: 2.625rem !important;
    letter-spacing: -0.36px !important;
    color: var(--primary74) !important;
}
.typoHelveticaRegoular14 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.0625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary214 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary21024 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary571024 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary21024 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary21622 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary791622 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary79) !important;
}
.typoRobotoMediumprimary21833 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 2.0625rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
}
.typoRobotoMediumprimary216 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary2) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoMediumprimary5716 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary57) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoMediumprimary216 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary2) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoRegularprimary571624 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegularprimary21624 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
}
.typoRobotoRegularprimary6614826 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.25rem !important;
    line-height: 1.625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
}
.typoRobotoRegularprimary6611826 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.25rem !important;
    line-height: 1.625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
}

.typoRobotoRegoularprimary2114 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: #427be0 !important;
}
.typoRobotoItalicRegoular1441 {
    font-family: RobotoItalic, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 2.5625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary3) !important;
}
.typoRobotoMediumprimary31421 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary31422 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary3814 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary38) !important;
}
.typoRobotoMedium1633 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 2.0625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary3);
    white-space: nowrap !important;
}
.typoRobotoMedium1633:active {
    color: var(--primary10) !important;
}
.typoRobotoRegularprimary1214 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px;
    color: var(--primary12);
    opacity: 1;
}
.typoRobotoRegularprimary121422 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: 0px;
    color: var(--primary12);
    opacity: 1;
}
.typoRobotoRegularprimary121417 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.9rem !important;
    line-height: 1.0625rem !important;
    letter-spacing: 0px;
    color: var(--primary12);
    opacity: 1;
}
.typoRobotoBoldprimary314 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary3) !important;
}
.typoRobotoBoldprimary6314 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
}
.typoRobotoRegoularprimary631419 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
}
.typoRobotoRegoularprimary631434 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoRegoularprimary6114 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary61) !important;
}
.typoRobotoRegoularprimary571641 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 2.5625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571622 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571822 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary57) !important;
}
.typoRobotoBoldarprimary571822 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571634 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    /* line-height: 2.125rem !important; */
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoBoldprimary571834 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1.125rem !important;
    /* line-height: 2.125rem !important; */
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoMediumprimary5718 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571441 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 2rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571627 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.6875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}

.typoRobotoBoldprimary71419 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: capitalize;
}
.typoRobotoBoldprimary21419 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
    text-transform: capitalize;
}
.typoRobotoMediumprimary262229 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.375rem !important;
    line-height: 1.8125rem !important;
    letter-spacing: 0 !important;
    color: var(--primary26) !important;
    text-transform: initial;
}
.typoRobotoMediumprimary261824 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary26) !important;
    text-transform: initial;
}
.typoRobotoNormalprimary262229 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.9375rem !important;
    line-height: 1.25rem !important;
    letter-spacing: 0 !important;
    color: var(--primary26) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary316 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary314 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary31621lh {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary3) !important;
}
.typoRobotoRegoularprimary2621lh {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary761421lh {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary76) !important;
}
.typoRobotoRegoularprimary31621l {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary3) !important;
    word-break: break-all !important;
    text-align: center !important;
    margin-top: 10px !important;
}
.typoRobotoRegoularprimary101621lh {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary10) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoRegoularprimary101621 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary10) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
    width: 80% !important;
}
.typoRobotoMediumprimary101621lh {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary10) !important;
}
.typoRobotoRegoularprimary91621lh {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary9) !important;
}
.typoRobotoRegoularprimary316spc {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary3) !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}
.typoRobotoRegoularprimary316break {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary3) !important;
    white-space: break-all !important;
}
.typoRobotoRegoularprimary5714 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary5714190 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary571434 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 2.125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary57) !important;
}
.typoRobotoBoldprimary6614 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
}
.typoRobotoRegularprimary661419 {
    font-family: RobotoRegular, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary66) !important;
}
.typoRobotoRegularprimary661434 {
    font-family: RobotoRegular, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 2.125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
}
.typoRobotoBoldprimary5716 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary57) !important;
}
.typoRobotoBoldprimary691621 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary69) !important;
}
.typoRobotoRegularprimary691621 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0.32px !important;
    color: var(--primary69) !important;
}
.typoRobotoMediumprimary721624 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary72) !important;
}
.typoRobotoRegoularprimary5914 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary59) !important;
}
.typoRobotoMediumprimary6014 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary60) !important;
    text-transform: initial;
}
.typoRobotoRedoularprimary601624 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary60) !important;
    text-transform: initial;
}
.typoRobotoMediumprimary814 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary8) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary1014 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary10) !important;
}
.typoRobotoRegoularprimary101216 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: 0 !important;
    color: var(--primary10) !important;
}
.typoRobotoMediumprimary1014 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary10) !important;
}
.typoRobotoMediumprimary101822 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary10) !important;
}
.typoRobotoRegoularprimary714 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary714pointer {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
    cursor: pointer !important;
}
.typoRobotoBoldprimary718 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoRegularprimary71824 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoBoldprimary218 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
    text-transform: initial;
}
.typoRobotoMediumprimary218 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0 !important;
    color: var(--primary2) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary716 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 2.5625rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary7160 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    /* line-height: 2.5625rem !important; */
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoRegoularprimary712 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: 0 !important;
    color: var(--primary7) !important;
    text-transform: initial;
}
.typoRobotoMediumprimary214 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    color: var(--primary2) !important;
    text-transform: initial;
}
.typoRobotoMedium14 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: -0.28px !important;
    text-transform: initial;
}
.typoRobotoMediumprimary801622 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    text-transform: initial;
    color: var(--primary80) !important;
}
.typoRobotoMediumprimary214190 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
    text-transform: initial;
}
.typoRobotoMediumprimary218124 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}

.typoRobotoMediumprimary121434 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.188rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoMediumprimary661434 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.188rem !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoRegularprimary631434 {
    font-family: RobotoNormal, sans-serif !important  ;
    font-size: 0.875rem !important;
    line-height: 1.188rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
    opacity: 1;
}

.typoRobotoMediumprimary1218124 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoRegularprimary1218124 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoMediumprimary1224124 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    text-transform: initial;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoMediumprimary1214 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1.375rem !important;
    line-height: 2.0625rem !important;
    letter-spacing: -0.44px !important;
    color: var(--primary12) !important;
}
.typoRobotoNormalprimary1216210 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoMediumprimary1216210 {
    font-family: RobotoMedium, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoNormalprimary122233 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1.375rem !important;
    line-height: 2.0625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoNormalprimary1214 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoNormalprimary121621 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    display: flow-root !important;
}
.typoRobotoBoldprimary1214 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 1.25rem !important;
    line-height: 1.625rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoBoldprimary121419 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
}
.typoRobotoitalic14 {
    font-family: RobotoItalic, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary3) !important;
}

.typoRobotoitalicprimary5714 {
    font-family: RobotoItalic, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    letter-spacing: -0.32px !important;
    color: var(--primary57) !important;
}
.typoRobotoRegoularprimary3314 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary33) !important;
}
.typoRobotoRegoularprimary7114 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary71) !important;
}
.typoRobotoRegoularprimary71621 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 1rem !important;
    line-height: 1.3125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary7) !important;
}
.typoRobotoRegoularprimary3312 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary33) !important;
}
.typoRobotoRegoularprimary6512 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary65) !important;
}
.typoRobotoRegoularprimary6514 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary65) !important;
}
/* .typoRobotoRegoularprimary3312 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary33)  !important;
} */
.typoRobotoRegoularprimary3514 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary35) !important;
}
.typoRobotoRegoularprimary214 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary2) !important;
}
.typoRobotoRegoularprimary3714 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary37) !important;
}
.typoRobotoRegoularprimary2914 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary29) !important;
}
.typoRobotoRegoularprimary3114 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary31) !important;
}
.typoRobotoBoldprimary631216 {
    font-family: RobotoBold, sans-serif !important ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
}
.typoRobotoRegoularprimary3214 {
    text-align: center !important;
    margin-top: 30px !important;
    /* margin-left: 30px !important; */
    color: #959daa !important;
    width: 350px !important;
    font-size: 16px !important;
}
.typoRobotoRegoularprimary3315 {
    text-align: center !important;
    margin-top: 30px !important;
}
.typoRobotoRegoularprimary121923 {
    font-family: RobotoNormal, sans-serif !important;
    font-style: normal !important;
    font-size: 1.125rem !important;
    font-weight: normal !important;
    line-height: 1.5rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    opacity: 1 !important;
}
.typoHelveticalBoldprimary121824 {
    font-family: RobotoBold, sans-serif !important;
    font-style: normal !important;
    font-size: 1.1875rem !important;
    font-weight: bold !important;
    line-height: 1.4375rem !important;
    letter-spacing: 0px !important;
    color: var(--primary12) !important;
    opacity: 1 !important;
}
.typoHelveticalNormalprimary631623 {
    font-family: RobotoNormal, sans-serif !important;
    font-style: normal !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: 1.4375rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
    opacity: 1 !important;
}
.typoHelveticalNormalprimary631234 {
    font-family: RobotoNormal, sans-serif !important;
    font-style: normal !important;
    font-size: 0.75rem !important;
    font-weight: normal !important;
    line-height: 2.125rem !important;
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
    opacity: 1 !important;
}
.typoHelveticalNormalprimary6312 {
    font-family: RobotoNormal, sans-serif !important;
    font-style: normal !important;
    font-size: 0.75rem !important;
    font-weight: normal !important;
    /* line-height: 2.125rem !important; */
    letter-spacing: 0px !important;
    color: var(--primary63) !important;
    opacity: 1 !important;
}

.typoRobotoRegoularprimary1 {
    font-family: RobotoNormal, sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 27px !important;
    font-size: 16px !important;
    color: #959daa !important;
    margin-bottom: 20px !important;
    display: flex !important;
    justify-content: center !important;
}
.typoRobotoRegoularprimary116 {
    font-family: Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 19px !important;
    font-size: 16px !important;
    color: #fff !important;
}
.typoRobotoRegoularprimary2 {
    text-align: center;
    margin-bottom: 30px !important;
}
.typoRobotoRegoularprimary3 {
    text-align: center;
    margin-top: 10px !important;
    font-size: 13px !important;
    color: #959daa !important;
}
.typoRobotoRegoularprimary4 {
    text-align: center !important;
    /* margin-top: 74px !important; */
    color: #363636 !important;
    font-size: 20px !important;
}
.typoRobotoRegoularprimary731419 {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    letter-spacing: 0px !important;
    color: var(--primary73) !important;
}
.typoRobotoRegularprimary21419 {
    text-align: center;
    color: var(--primary2) !important;
    font-family: RobotoRegular, sans-serif !important ;
    font-size: 0.875rem !important;
    letter-spacing: 0px !important;
    line-height: 1.188rem !important;
    opacity: 1 !important;
}
.typoRobotoRegularblueprimary21419 {
    text-align: center;
    color: var(--primary10) !important;
    font-family: RobotoRegular, sans-serif !important ;
    font-size: 0.875rem !important;
    letter-spacing: 0px !important;
    line-height: 1.188rem !important;
    opacity: 1 !important;
}
.typoRobotoRegulargreyprimary21419 {
    color: var(--primary66) !important;
    font-family: RobotoRegular, sans-serif !important ;
    font-size: 0.875rem !important;
    letter-spacing: 0px !important;
    line-height: 1.188rem !important;
    opacity: 1 !important;
}
.typoRobotoRegulargreyprimary661622 {
    font-family: RobotoNormal, sans-serif !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
    opacity: 1 !important;
}
.typoRobotoMediumreyprimary661621 {
    font-family: RobotoMedium, sans-serif !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    color: var(--primary66) !important;
    opacity: 1 !important;
}
.typoRobotoRegulargreyprimary551621 {
    font-family: RobotoNormal, sans-serif !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    color: var(--primary55) !important;
    opacity: 1 !important;
}
.typoRobotoRegulargreyprimary551621Overflow {
    font-family: RobotoNormal, sans-serif !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    color: var(--primary55) !important;
    opacity: 1 !important;
    display: flow-root !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.typoRobotoScheduled {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--Received) !important;
}
.typoRobotoProcessing {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--Received) !important;
}
.typoRobotoReceived {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--Received) !important;
}
.typoRobotoUnableToProcess {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--UnableToProcess) !important;
}
.typoRobotoReadyForAudit {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--ReadyForAudit) !important;
}
.typoRobotoAuditInProgress {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AuditInProgress) !important;
}
.typoRobotoCompleted {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--Completed) !important;
}
.typoRobotoAddendumReceived {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AddendumReceived) !important;
}
.typoRobotoAddendumReady {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    width: 150px;
    text-wrap: wrap;
    color: var(--AddendumReady) !important;
}
.typoRobotoAddendumInProgress {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AddendumInProgress) !important;
}
.typoRobotoAddendumMerged {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AddendumMerged) !important;
}
.typoRobotoAddendumCompleted {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AddendumCompleted) !important;
}
.typoRobotoAddendumReceived {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AddendumReceived) !important;
}
.typoRobotoValidated {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--Validate) !important;
}
.typoRobotoAuditSuspended {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AuditSuspended) !important;
}
.typoRobotoAuditOverdue {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AuditOverdue) !important;
}
.typoRobotoAuditEscalated {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--AuditEscalated) !important;
}
.typoRobotoSentToInterface {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--SentToInterface) !important;
}
.typoRobotoNoProcessRequired {
    font-family: RobotoNormal, sans-serif !important ;
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    letter-spacing: 0px !important;
    color: var(--NoProcess) !important;
}
