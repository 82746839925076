.ImagePreview {
    object-fit: contain !important;
}

.cross_Btn {
    position: absolute !important;
    margin-bottom: -25px !important;
    margin-right: -25px !important;
    z-index: 1 !important;
}

.disableImageGallery {
    pointer-events: none !important;
    user-select: none !important;
}

.crossIcon {
    width: 35px !important;
    height: 34px !important;
}

.header {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end !important;
}