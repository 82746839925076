.TATMeterContainer {
    height: 45dvh;
    width: 49dvw;
}
.TATMeterTableContainer {
    width: 14dvw;
    min-width: 160px;
}

@media screen and (min-width: 2000px) {
    .TATMeterContainer {
        height: 45dvh;
        width: 40dvw;
    }
}
