

.subject {
    min-height: 45px !important ;
  
}
.text {
    padding: 10px 30px 0 30px !important;
    color: #ffffff !important;
}
.align {
    display: flex !important;
    flex-direction: row !important;
    margin-left: 29px !important;
    background: #58b597 !important;
}
.navDiv {
    display: flex !important;
    flex-direction: row !important;
    margin-right: 16px !important;
}
.rootmsg {
    overflow-y: auto !important;
    /* height: auto !important; */
    overflow-x: hidden;
    /* height:calc(100vh - 425px) !important;  */
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
    /* margin-bottom: 73px !important;  */
    gap: 5px !important;
}
.sendIcon {
    fill: #ffffff;
    height: 40px;
    width: 40px;
}
.sendIcon:active {
    color: #427be0 !important;
    fill: #427be0 !important;
}
.sendIcon:disabled path {
    fill: rgb(124, 124, 124) !important;
    stroke: rgb(124, 124, 124) !important;
}
.iconBtn {
    width: 40px;
    height: 40px;
}

.cmtSendDiv {
    min-height: 71px !important;
    /* UI Properties */
    background: #f7f8f9 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000f !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    padding: 8px !important;
    bottom: 0 !important;
    position: absolute !important;
}

.cmtSendtextfd {
    /* min-height: 55px !important; */
    width: 100% !important;
    margin: 8px !important;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 28px !important;
    opacity: 1 !important;
}
