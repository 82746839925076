.LastConversation{
    flex-grow: 1 !important;
    position: relative !important;
    z-index: 100 !important;
    height: 100% !important;
    overflow: hidden !important;
}
.mainDiv{
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    transition: background-color .3s ease !important;
}
.headerDiv{
    position: relative !important;
    z-index: 1000 !important;
    box-sizing: border-box !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    flex-direction: column !important;
}
.bodyDiv{
    position: relative !important;
    z-index: 1 !important;
    flex: 1 1 0 !important;
    order: 2 !important;
}
.bodyPrimaryDiv{
    position: absolute;
    top: 0;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.listItem{
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 10px 16px 10px 24px !important;
}
.toolbarroot{
    justify-content: space-between !important;
    width: 75% !important;
    flex-direction: row !important;
}
.toolbar{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.tagtoolbar{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    align-self: flex-start !important;
}

.btnforward{
    height: 30px !important;
    width: 30px !important;
}
.arrowIcon{
    font-size: 16px !important;
    font-weight: normal !important;
    color: #959DAA !important;
}
.userDiv{
    width: 100% !important;
    /* gap: 3px !important;
    display: flex !important;
    flex-direction: column !important; */
}
.textmsg{
    white-space: nowrap !important;
}
.lastConversationCenter{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    top: 45% !important;
    position: absolute;
    width: 100%;
}
.list{
    overflow: hidden !important;
    overflow-y: auto !important;
    padding-top: 0 !important;
    height: calc(100vh - 250px) !important;
}
.overflw{
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}