.VideoPlayer {
    height: 100% !important;
    width: 100% !important;
    background: black !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
}

.player_root {
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100%;
    min-width: 100%;}

.footer {
    width: 100% !important;
    display: flex !important;
}
.Volume__root{
    height: 100px !important;
    width: 30px !important;
    background: gray !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px !important;
}
.cross_Btn{
    position: absolute;
    margin-bottom: -25px;
    margin-right: -20px;
    z-index: 1000;
}
.crossIcon{
    width: 30px !important;
    height: 32px !important;
}
.header{
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-end !important;
}
.footer_sub{
    display: flex !important;
    flex-direction: row !important;
    width: inherit !important;
    background: #363636 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 4px #00000014 !important;
    opacity: 1 !important;
    justify-content: center !important;
    align-items: center !important;
    padding-left: 15px !important;
    padding-right: 10px !important;
    position: unset !important;
    margin-top: -40px !important;
    /* gap: 10px !important; */
}