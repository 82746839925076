.PatientHolder {
    min-height: 51px !important;
    background: #F5F5F5 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center !important; */
    padding: 20px 20px 20px 20px !important;
    width: 100% !important;
    gap: 10px !important;
}
.patientNameDiv{
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center !important; */
    /* word-break: break-word !important; */
    min-width: 50% !important;
    white-space: nowrap !important;
}
.patientMrnDiv{
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center !important; */
    /* word-break: break-all !important; */
    min-width: 50% !important;
}
.namePad{
    padding-left: 8px !important;
}
.mrnPad{
    padding-left: 8px !important;
}