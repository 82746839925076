.ProgressLoader {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: inherit !important;
}
.progressDiv{
    border-radius: 8px;
    opacity: 1;
    height: 79px !important;
    width: 402px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px 12px 0px 15px !important;
}
.progressSecDiv{
    height: inherit !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 90% !important;
    justify-content: center !important;
    gap: 7px !important;
}
.lableDiv{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
}
.barDiv{
    display: flex !important;
    margin-top: -25px !important;
    align-items: flex-end !important;
    height: 100% !important;
}
.barDi{
    width: 100% !important;
}
.barSce{
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    height: 100% !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 20px 16px 20px 24px !important;
}
.fNStyle{
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}