.cardDiv {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #e2dfdf !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 297px !important;
    height: 42px !important;
    text-transform: none !important;
}
/* .fileItem {
    margin-bottom: 10px !important;
} */

.crossIcon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    width: 42px !important;
    height: 40px !important;
    cursor: pointer !important;
}

.details {
    display: flex !important;
    /* flex-direction: column !important;
    margin-left: 20px !important; */
    text-align: start !important;
    align-items: center !important;
}

.svgStyles {
    width: 46px !important;
}

.rowDiv {
    display: flex !important;
    flex-direction: row !important;
}

.medirowDiv {
    width: 64px !important;
    height: 64px !important;
    opacity: 1 !important;
    background-color: black !important;
    padding: 0;
    border-radius: 16px !important;
    cursor: pointer;
    position: relative;
  }
  .crossdiv{
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    transform: translate(50%, -50%) !important;
    cursor: pointer !important;
    
  }
  
  .imageContainer {
   
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
  }
  
  .playButtonContainer {
   
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
   
  }
  .imageContainerOverlay {
    position: absolute !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: inherit !important;
    width: inherit !important;
   
   
  }
  .playButton {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  
  