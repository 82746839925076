.buttonstyle {
    flex: 0 0 auto !important;
    background: #417bdf 0% 0% no-repeat padding-box !important;
    border: 1px solid #417bdf !important;
    border-radius: 28px !important;
    opacity: 1 !important;
    height: 28px !important;
    text-transform: none !important;
   
}
.removestyle{
    background: #417bdf 0% 0% no-repeat padding-box !important;
    border: 1px solid #417bdf !important;
    border-radius: 28px !important;
    opacity: 1 !important;
    height: 28px !important;
    text-transform: none !important;
    margin-right: 5px !important;
}
.removebuttondiv{
    display:flex !important;
    flex-direction: row !important;
    align-self: center !important;
}
.listItem {
    justify-content: space-between !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    background: none !important;
    padding: 16px 38px 7px 24px !important;
    cursor: pointer !important;
}
.listItem:hover span {
    color: #427be0 !important;
    cursor: pointer !important;
}
.toolbarroot {
    justify-content: space-between !important;
    width: 100% !important;
}
.toolbar {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 18px !important;
}
.listItems {
    padding: 0 !important;
    background: none !important;
}
.list {
    overflow: hidden !important;
    overflow-y: auto !important;
    padding-top: 0 !important;
    height: calc(100vh - 231px) !important;
}
