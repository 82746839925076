.JobAuditorGraphContainer {
    height: 45dvh;
    width: 49dvw;
}

@media screen and (min-width: 2000px) {
    .JobAuditorGraphContainer {
        height: 45dvh;
        width: 40dvw;
    }
}
