.TablePaginations {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
    align-items: center !important;
    margin-bottom: 20px !important;
}
.secondarybutton {
    height: 32px !important;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #dddddd !important;
    border-radius: 16px !important;
    opacity: 1 !important;
}
.paginationDiv {
    display: flex !important;
    justify-content: center !important;
    /* justify-content: flex-end !important; */
    align-items: flex-end !important;
    margin-top: 20px !important;
}
