.UserAvatarMap {
    display: flex !important;
    flex-direction: row !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    margin-top: 2px !important;
}
.userAvatarDiv{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 8px !important;
}
.overflowDiv{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}