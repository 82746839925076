.root{
    width: 500px;
    max-height: 227px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    opacity: 1;
}
.sucessroot{
    width: 460px !important;
    max-height: 224px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #E6E6E6 !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}