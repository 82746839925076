.AudioPlayer {
    width: 413px !important;
    height: 180px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 12px !important;
    opacity: 1 !important;
}
.cross_Btn{
    position: absolute !important;
    margin-top: -27px !important;
    right: 5px !important;
}
.name_root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.audio_root{
    padding: 24px !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    height: 100% !important;
}
.control_btn_root{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row !important;
    gap: 8px !important;
}
.control_btn{
    width: 50px !important;
    height: 50px !important;
    padding: 0px !important;
}
.PlayIcon{
    width: 45px !important;
    height: 45px !important;
}
.sliderTimer{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
.durTime{
    margin-right: -15px !important;
}
.curTime{
    margin-left: -15px !important;
}
.loder_rootDiv{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
}
.crossIcon{
    width: 30px !important;
    height: 32px !important;
}
.brokenPlaceholder{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}