.startAttachmentMessage {
    width: 342px !important;
    height: 53px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    align-self: flex-end;
    align-items: center;
    display: flex;
    margin-right: 20px !important;
    margin-top: 15px !important;
    text-transform: inherit;
}
.endAttachmentMessage {
    width: 342px !important;
    height: 53px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    align-self: flex-start;
    align-items: center;
    display: flex;
    margin-left: 20px !important;
    margin-top: 15px !important;
}
.startAttachment {
    width: 220px !important;
    height: 178px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 16px !important;
    opacity: 1 !important;
    align-self: flex-end;
    align-items: center;
    display: flex;
    margin-right: 20px !important;
    margin-top: 15px !important;
}
.endAttachment {
    width: 220px !important;
    height: 178px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #E2DFDF !important;
    border-radius: 16px !important;
    opacity: 1 !important;
    align-self: flex-start;
    align-items: center;
    display: flex;
    margin-left: 20px !important;
    margin-top: 15px !important;
}
.primaryRoot{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 13px ;
    padding-left: 6px !important;
    /* width: 80% !important ; */
}
.root{
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
}
.textWrap{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.curPoi{
    cursor: pointer !important;
}
.curText{
    cursor: text !important;
}
.showdow{
    position: absolute !important;
    background-image:  linear-gradient(to top,rgba(11,20,26,0.2),rgba(11,20,26,0)) !important;
    height: 28px !important;
    width: inherit !important;
    align-self: flex-end !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    cursor: pointer !important;
}
.topshowdow{
    position: absolute !important;
    background-image:  linear-gradient(to bottom,rgba(11,20,26,0.2),rgba(11,20,26,0)) !important;
    height: 28px !important;
    width: inherit !important;
    align-self: flex-start !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    cursor: pointer !important;
    z-index: 1;
}
.imagessx{ 
    border-radius: 15px !important;
    object-fit:cover !important;
    cursor: pointer !important;
    width: 100% !important;
    opacity: 1;
    background: #cccc;
}
.videossx{ 
    border-radius: 16px !important;
    object-fit: cover !important;
    cursor: pointer !important;
    opacity: 1;
    width: 100% !important;
    height: 100% !important;
    min-height: 178px !important;
}
.playIcon{
    position: absolute !important;
    width: inherit !important;
    height: inherit;
    display: flex !important;
    justify-content: center !important;
    border-radius: 16px !important;
}
.mediaDiv{
    height: 178px !important;
    width: 220px !important;
    border-radius: 16px !important;
    padding: 0px !important;
    position: relative !important;
}
.rowdiv{
    display: flex !important;
    flex-direction: row !important;
}
.imageContainerOverlay{
    position: absolute !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: inherit !important;
    width: inherit !important;
}