
.crossIcon{
    width: 25px !important;
    height: 24px !important;
}
.header{
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}
.documentViewer{
    width: 100% !important;
    height: 100% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    padding-top: 2% !important;
    padding-bottom: 5% !important;
}
.brokenPlaceholder{
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}