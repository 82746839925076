.Comments {
    display: flex !important;
    flex-Direction: column !important;
    align-items: center !important;
    /* margin-top: 20px !important; */
    /* margin-bottom: 75px !important; */
    /* background: red !important; */
    height: 100% !important;
}
.CommentsDiv{
    /* display: flex !important;
    flex-direction: column !important;
    align-Items: center !important; */
    background: green !important;
    gap: 10px !important;
    /* position: relative !important;
    height: 500px !important;
    overflow-y: scroll !important; */
}
.cardComment{
    display: flex !important;
    flex-direction: row !important;
    gap: 15px !important;
    padding: 16px 16px 5px 16px !important;
}
.cardCommentDiv{
    display: flex !important;
    align-items: baseline !important;
}
.cardAction{
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px 1px 8px 1px !important;
}
.cardActionDiv{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    margin-left: 20px !important;
}
.cardEditActionDiv{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    /* margin-left: 20px !important; */
}
.cardContentDiv{
    display: flex !important;
    flex-direction: row !important;
    /* margin-left: 20px !important; */
    padding: 1px !important;
    justify-content: space-between !important;
}
.cardContentPrimaryDiv{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
}
.TaskHeader{
    display: flex !important;
    margin-left: 20px !important;
    margin-right: 24px !important;
    height: 80px !important;
    min-height: 80px !important;
    justify-content: space-between !important;
    align-items:center !important;
}
.sendIcon {
    fill: #FFFFFF;
    height:40px;
    width:40px
}
.sendIcon:active {
    color: #427BE0 !important;
    fill: #427BE0 !important;
}
.sendIcon:disabled path{
    fill: rgb(124, 124, 124) !important;
    stroke: rgb(124, 124, 124) !important;
}
.iconBtn{
    width: 40px;
    height: 40px;
}
.cmtSendDiv {
    min-height: 71px !important;
    /* UI Properties */
    background: #F7F8F9 0% 0% no-repeat padding-box !important;
    box-shadow: -4px 1px 8px #0000000F !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    padding: 8px !important;
    bottom: 0 !important;
    position: absolute !important;
}

.cmtSendtextfd {
    min-height: 55px !important;
    width: 100% !important;
    margin: 8px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 28px !important;
    opacity: 1 !important;
}
.header{
    padding: 20px !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100% !important;
}