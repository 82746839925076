.mediaRoot {
  overflow-y: auto !important;
  height: calc(100vh - 231px) !important;
  overflow-x: hidden !important;
}
.medirowDiv {
  width: 120px !important;
  height: 109px !important;
  opacity: 1;
  background-color: black !important;
  padding: 0;
  border-radius: 8px !important;
  cursor: pointer;
}
.imageContainerOverlay {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: inherit !important;
  width: inherit !important;
  margin-left: 35px !important;
  margin-top: 13px !important;
 
}

.imageContainer {
  position: relative;
  display: inline-block;
}
.playButtonContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nomediaCenter{
  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    top: 45% !important;
    position: absolute;
    width: 100%;
}
.searchDiv{
  padding: 15px !important;
}