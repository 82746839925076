.dialog_root_div{
    padding: 20px !important;
}
.spec_div{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
}
.provider_div{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
}
.manager_div{
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
}
.footer{
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
    gap: 10px !important;
}
.chip{
    padding: 5px !important;
    padding-left: 10px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}