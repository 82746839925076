.Difference > span {
    background-color: rgb(255, 247, 176);
    text-decoration: none;
}
.Difference > del {
    text-decoration: none;
}

.Difference > ins {
    /* background-color: rgb(255, 247, 176); */
    text-decoration: none;
}
