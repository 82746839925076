.ModalDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ModalContainer {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 50dvw;
    background-color: white;
    /* border: 2px solid #000; */
    box-shadow: 24;
    max-height: fit-content;
    height: 100%;
    overflow: scroll;
}

.headerText {
    font-weight: 500;
}
