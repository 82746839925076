.popPaper {
    width: 195px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 14px !important;
    box-shadow: 0px 3px 6px #00000029 !important;

  }
  .menuItem{
    border-bottom: 1px solid #F5F5F5 !important;
    width: 180px !important;
}