.labelTypo{
    padding: 3px 8px 3px 8px !important;
}
.rootBox{
    margin: 30px 20px !important
}
.LabelOneBox{
    display: flex !important
}
.LabelTwoBox{
    margin-top: 44px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.LabelThreeBox{
    margin-top: 37px !important
}
.DivBTNBox{
    display: flex !important;
    justify-content: center !important;
    margin-top: 63px !important;
}
.userDiv{
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    align-items: center !important;
    padding: 8px !important;
}
.TaskHeader{
    display: flex !important;
    margin-left: 20px !important;
    margin-right: 24px !important;
    height: 80px !important;
    min-height: 80px !important;
    justify-content: space-between !important;
    align-items:center !important;
}